import * as Fathom from 'fathom-client'

export const initAnalytics = () => {
  console.log('Analytics Init')
  let fathomId = process.env.DEV ? 'EICMQDUH' : 'RIMFYEXP'
  Fathom.load(fathomId, { includedDomains: ['www.challengehound.com', 'beta.challengehound.com'] })
}

export const logPageView = () => {
  if (process.env.DEV) console.log(`Analytics: pageview for ${window.location.pathname}`)
  Fathom.trackPageview()
}

export const logEvent = (key, value) => {
  if (key) {
    if (process.env.DEV) console.log('Analytics Event', { key, value })
    Fathom.trackEvent(key, { '_value': parseInt(value, 10) })
  }
}
